import React, { useEffect } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../store/slice/auth";

function Unauthorised() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col md:flex-row md:max-w-xxl rounded-lg bg-white shadow-lg">
        <img
          className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-96 md:rounded-none md:rounded-l-lg"
          src="assets/images/401-error-unauthorized.webp"
          alt=""
        />
        <div className="p-5 flex flex-col w-96 justify-start">
          <h5 className="text-center text-gray-900 text-xl font-medium mt-5">
            Unauthorized
          </h5>
          <p className="my-5 font-normal text-gray-700">
            You don't have access to this route. Please contact your
            administrator to get access the role.
          </p>
          <Button
            type="primary"
            className="mt-auto"
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Unauthorised;
