import React from "react";
import { useSelector } from "react-redux";
import AppControl from "../../components/";

const StudentInfo = () => {
  const pages = useSelector((state) => state.common.pages);
  const currentPage =
    pages && pages.filter((item) => item.formName === "StudentInfo");
  const currentPageControls = currentPage && currentPage[0].fields;
  const branchListBox = useSelector((state) => state.branches.listBox);
  const sessionListBox = useSelector((state) => state.academicyears.listBox);
  const classListBox = useSelector((state) => state.academicclasses.listBox);
  const sectionListBox = useSelector((state) => state.sections.listBox);

  let options = {};

  options["gender"] = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  options["bloodGroup"] = [
    {
      label: "A+",
      value: "A+",
    },
    {
      label: "O+",
      value: "O+",
    },
    {
      label: "B+",
      value: "B+",
    },
    {
      label: "AB+",
      value: "AB+",
    },
    {
      label: "A-",
      value: "A-",
    },
    {
      label: "O-",
      value: "O-",
    },
    {
      label: "B-",
      value: "B-",
    },
    {
      label: "AB-",
      value: "AB-",
    },
  ];
  options["nationality"] = [
    {
      label: "Indian",
      value: "Indian",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  options["caste"] = [
    {
      label: "GENERAL",
      value: "GENERAL",
    },
    {
      label: "SC",
      value: "SC",
    },
    {
      label: "ST",
      value: "ST",
    },
    {
      label: "OBC-A",
      value: "OBC-A",
    },
    {
      label: "OBC-B",
      value: "OBC-B",
    },
  ];
  options["motherTongue"] = [
    {
      label: "Bengali",
      value: "Bengali",
    },
    {
      label: "Hindi",
      value: "Hindi",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  options["religion"] = [
    {
      label: "Hinduism",
      value: "Hinduism",
    },
    {
      label: "Islam",
      value: "Islam",
    },
    {
      label: "Christianity",
      value: "Christianity",
    },
    {
      label: "Sikhism",
      value: "Sikhism",
    },
    {
      label: "Buddhism",
      value: "Buddhism",
    },
    {
      label: "Jainism",
      value: "Jainism",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  if (branchListBox) options["branchId"] = branchListBox;
  if (sessionListBox) options["sessionId"] = sessionListBox;
  if (classListBox) options["classId"] = classListBox;
  if (sectionListBox) options["sectionId"] = sectionListBox;

  return (
    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
      {currentPageControls &&
        currentPageControls.map((control) => (
          <AppControl
            key={control.fieldId}
            control={control.type}
            label={control.label}
            name={control.fieldId}
            placeholder={control.placeholder}
            options={control.options && options[control.fieldId]}
          />
        ))}
    </div>
  );
};

export default StudentInfo;
