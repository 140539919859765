import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#3b5998] text-center lg:text-left">
      <div className="text-slate-50 text-center p-4">
        © {new Date().getFullYear()} Copyright: Vidyakalpa Group Of Schools.
      </div>
    </footer>
  );
};
export default Footer;
