import * as yup from "yup";

export function createYupSchema(schema, config) {
  const { fieldId, validationType, validations } = config;
  //console.log(validations);
  if (validations) {
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    // console.log(validator);
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        //console.log(validation);
        if (type === "refValue") {
          const refValueParams = [[yup.ref(params[0])], params[1]];
          validator = validator["oneOf"](...refValueParams);
          schema[fieldId] = validator;
          return schema;
        }
        return;
      }
      // console.log(type, params);
      validator = validator[type](...params);
      // console.log(validator);
    });
    schema[fieldId] = validator;
  }
  return schema;
}
