import React from "react";

const Card = (props) => {
  const { title, extra } = props;
  return (
    <div className="flex justify-center">
      <div className="block w-full rounded-lg bg-white shadow-lg">
        <div className="flex justify-between border-b-2 border-neutral-100 py-3 px-6">
          <div>
            <h5 className="text-lg font-bold leading-tight text-neutral-800">
              {title}
            </h5>
          </div>
          <div>{extra}</div>
        </div>
        <div className="p-5">{props.children}</div>
      </div>
    </div>
  );
};

export default Card;
