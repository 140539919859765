import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Modal, Upload } from "antd";
import TextError from "./TextError";
import api from "../../store/client";

function AvatarImg(props) {
  const { label, name, placeholder, url, ...rest } = props;
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "Profile.png",
      status: "done",
      url,
    },
  ]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const customRequest = (options) => {
    const reader = new FileReader();
    reader.readAsDataURL(options.file);
    const data = new FormData();
    data.append("file", options.file);
    const config = {
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
      },
    };
    api.post("User/UpdateProfilePic", data, config).then(function (response) {
      console.log(response.data);
      // var result = {
      //   name: response.data.profilePicture,
      //   status: "done",
      //   url: `http://localhost:83/ProfileImages/${response.data.profilePicture}`,
      //   thumbUrl: `http://localhost:83/ProfileImages/Thumbs/${response.data.profilePicture}`,
      // };
      options.onSuccess(response.data, options.file);
    });
  };
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div>
          <div className="mb-1">
            <label htmlFor={name}>{label}</label>
          </div>
          <div className="mb-1">
            <ImgCrop rotationSlider>
              <Upload
                customRequest={customRequest}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </ImgCrop>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
          <ErrorMessage component={TextError} name={name} />
        </div>
      )}
    </Field>
  );
}

export default AvatarImg;
