import React, { useEffect } from "react";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../components/";
import { createYupSchema } from "../utilities/";
import { fetchRegisterForm } from "../store/slice/common";
import { registerUser } from "../store/slice/auth";
import AppNotificationContainer from "../components/controls/AppNotificationContainer";

function Register() {
  const { message } = App.useApp();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { page, isLoading, failedMessage } = useSelector(
    (state) => state.common
  );
  const pageControls = page && page.fields;
  console.log(page);
  useEffect(() => {
    dispatch(fetchRegisterForm());
  }, []);

  let initialValues = {},
    yepSchema,
    validationSchema;
  if (pageControls) {
    pageControls.map(
      (control) => (initialValues[control.fieldId] = control.defaultValue)
    );
    //   initialValues["roles"] = [];
    yepSchema = pageControls.reduce(createYupSchema, {});
    validationSchema = Yup.object().shape(yepSchema);
  }

  console.log("initialValues", initialValues["roles"]);
  const onSubmit = async (values) => {
    console.log("Form data", values);

    //values.userName = values.email;
    values.applicationName = "Vidyakalpa";

    const resultAction = await dispatch(registerUser(values));
    console.log("resultAction", resultAction);
    if (registerUser.fulfilled.match(resultAction)) {
      //message.success(resultAction.payload.message);
      navigate("/register-success");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        //formikHelpers.setErrors(resultAction.payload.field_errors)
      } else {
        message.error(`Save failed: ${resultAction.error.message}`);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <div className="flex justify-center items-center min-h-screen">
            <div className="block max-w-md rounded-lg bg-white p-6 shadow-lg">
              <h5 className="mb-3 text-xl font-medium leading-tight text-neutral-800 text-center">
                Register
              </h5>
              <Form>
                <div className="flex-col p-3">
                  {pageControls &&
                    pageControls.map((control) => (
                      <AppControl
                        key={control.fieldId}
                        control={control.type}
                        label={control.label}
                        name={control.fieldId}
                        placeholder={control.placeholder}
                      />
                    ))}
                </div>
                <AppControl
                  control="button"
                  type="submit"
                  name="Register"
                  size="md"
                  color="orange"
                  disabled={!formik.isValid || isLoading}
                  loading={isLoading}
                />
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="md"
                  color="blue"
                  onClick={() => {
                    navigate("/users");
                  }}
                />
              </Form>
            </div>
            <AppNotificationContainer
              loading={isLoading}
              error={failedMessage}
            />
          </div>
        );
      }}
    </Formik>
  );
}

export default Register;
