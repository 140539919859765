import React from "react";
import AvatarImg from "./controls/Avatar";
import Button from "./controls/Button";
import Input from "./controls/Input";
// import Textarea from './Textarea'
import Select from "./controls/Select";
import SelectWithSearch from "./controls/SelectWithSearch";
import MultipleSelect from "./controls/MultipleSelect";
import RadioButtons from "./controls/RadioButtons";
import Checkbox from "./controls/Checkbox";
import ToggleSwitch from "./controls/Switch";
import DatePicker from "./controls/DatePicker";
import Tree from "./controls/Tree";
import Table from "./controls/table/";
import Owner from "./controls/OwnerForm";

function AppControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "avatar":
      return <AvatarImg {...rest} />;
    case "button":
      return <Button {...rest} />;
    case "text":
      return <Input type="text" {...rest} />;
    case "password":
      return <Input type="password" {...rest} />;
    // case 'textarea':
    //   return <Textarea {...rest} />
    case "select":
      return <Select {...rest} />;
    case "selectwithsearch":
      return <SelectWithSearch {...rest} />;
    case "multiselect":
      return <MultipleSelect {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "switch":
      return <ToggleSwitch {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    // case 'chakraInput':
    //   return <ChakraInput {...rest} />
    case "tree":
      return <Tree {...rest} />;
    case "table":
      return <Table {...rest} />;
    case "owner":
      return <Owner {...rest} />;
    default:
      return null;
  }
}

export default AppControl;
