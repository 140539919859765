import React from "react";

function Welcome() {
  return (
    <>
      <div className="p-6 shadow-lg rounded-lg text-gray-700">
        <h2 className="font-semibold text-3xl mb-5">Welcome to Vidyakalpa!</h2>
        <hr className="my-6 border-gray-300" />
        <p>Thank you for registration in our website.</p>
        <p>Please contact your administrator to assign a Role to you.</p>
      </div>
    </>
  );
}

export default Welcome;
