import React from "react";
import { Radio } from "antd";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { value } = field;
        var defaultValue = value;
        const onChange = (e) => {
          form.setFieldValue(name, e.target.value);
        };

        return (
          <div>
            <div className="mb-1">
              <label htmlFor={name}>{label}</label>
            </div>
            <div className="mb-1">
              <Radio.Group
                onChange={onChange}
                options={options}
                value={defaultValue}
              />
            </div>
            <ErrorMessage component={TextError} name={name} />
          </div>
        );
      }}
    </Field>
  );
}

export default RadioButtons;
