import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../components/";
import { loginUser } from "../store/slice/auth";
import AppNotificationContainer from "../components/controls/AppNotificationContainer";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isLoading, message, error } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, []);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
        <img
          className="w-full h-48 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
          src="assets/images/school.jpg"
          alt=""
        />
        <div className="p-6 flex flex-col w-96 justify-start">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <h5 className="text-gray-900 text-xl font-medium mb-2">
                    Forgot Password ?
                  </h5>
                  <AppControl
                    control="text"
                    placeholder="Email"
                    label="Enter your email to reset your password"
                    name="email"
                  />
                  <div className="p-3">
                    <AppControl
                      control="button"
                      type="submit"
                      name="Submit"
                      size="md"
                      color="orange"
                      disabled={!formik.isValid || isLoading}
                      loading={isLoading}
                    />
                    <AppControl
                      control="button"
                      type="reset"
                      name="Cancel"
                      size="md"
                      color="blue"
                      onClick={() => {
                        navigate("/login");
                      }}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AppNotificationContainer
        loading={isLoading}
        msg={message}
        error={error}
      />
    </div>
  );
}

export default ForgotPassword;
