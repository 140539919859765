import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import api from "../client";

export const getBranches = createAsyncThunk(
  "Branch/list",
  async (thunkAPI) => {
    const url = "branch";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { lastFetch } = getState().branches;
      const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
      if (diffInMinutes < process.env.REACT_APP_CACHE_TIME) return false;
    },
  }
);
export const getBranch = createAsyncThunk("User", async (id, thunkAPI) => {
  const url = `user/${id}`;
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const saveBranch = createAsyncThunk(
  "Branch/save",
  async (branch, thunkAPI) => {
    const url = "branch";
    return api.post(url, branch).then(function (response) {
      return response.data;
    });
  }
);
export const updateBranch = createAsyncThunk(
  "User/update",
  async (user, thunkAPI) => {
    const url = `user/${user.id}`;
    return api.put(url, user).then(function (response) {
      return response.data;
    });
  }
);
export const deleteBranch = createAsyncThunk(
  "User/delete",
  async (id, thunkAPI) => {
    const url = `user/${id}`;
    return api.delete(url).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  listBox: [],
  isLoading: false,
  message: null,
  lastFetch: null,
};

const slice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBranch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
        state.lastFetch = Date.now();
        let schools = [];
        action.payload.data.forEach((item) => {
          schools.push({
            label: item.branchName,
            value: item.id,
          });
        });
        state.listBox = schools;
      })
      .addCase(getBranches.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //   .addCase(getUser.pending, (state, action) => {
      //     state.isLoading = true;
      //   })
      //   .addCase(getUser.fulfilled, (state, action) => {
      //     state.user = action.payload.data;
      //     state.isLoading = false;
      //   })
      //   .addCase(getUser.rejected, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload;
      //   })
      .addCase(saveBranch.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(saveBranch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(saveBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //   .addCase(updateUser.pending, (state, action) => {
      //     state.isLoading = true;
      //     state.message = "";
      //   })
      //   .addCase(updateUser.fulfilled, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload.message;
      //   })
      //   .addCase(updateUser.rejected, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload;
      //   })
      //   .addCase(deleteUser.pending, (state, action) => {
      //     state.isLoading = true;
      //     state.message = "";
      //   })
      //   .addCase(deleteUser.fulfilled, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload.message;
      //   })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default slice.reducer;
