import React from "react";
import { Field, ErrorMessage } from "formik";
import { Switch } from "antd";
import TextError from "./TextError";

const ToggleSwitch = (props) => {
  const { label, name, placeholder, ...rest } = props;

  return (
    <div>
      <div className="mb-2">
        <Field name={name}>
          {({ field, form }) => {
            const { value } = field;
            var checked = value;
            // console.log(`checked = ${checked}`);
            const onChange = (checked) => {
              //   console.log(`checked = ${checked}`);
              form.setFieldValue(name, checked);
            };

            return (
              <>
                <Switch id={name} checked={checked} onChange={onChange} />
                <label className="ml-2 cursor-pointer" htmlFor={name}>
                  {label}
                </label>
              </>
            );
          }}
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default ToggleSwitch;
