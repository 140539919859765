import React, { useEffect } from "react";
import { Button, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";
import { SiGoogleclassroom } from "react-icons/si";
import AppControl from "../../components";
import Card from "../../components/controls/Card";
import { getSections } from "../../store/slice/sections";

const Section = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSections());
  }, []);
  const sectionList = useSelector((state) => state.sections.list);

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
      isVisible: false,
      disableFilters: true,
      disableExport: true,
    },
    {
      Header: "Class Name",
      accessor: "classes[0].className",
    },
    {
      Header: "Section",
      accessor: "sectionName",
      disableFilters: true,
    },
    {
      Header: "Student Capacity",
      accessor: "studentCapacity",
      disableFilters: true,
    },
    {
      Header: "Bench Capacity",
      accessor: "benchCapacity",
      disableFilters: true,
    },
    {
      Header: "Room No",
      accessor: "roomNo",
      disableFilters: true,
    },
    {
      Header: "Floor",
      accessor: "floor",
      disableFilters: true,
    },
    {
      Header: "Building",
      accessor: "building",
      disableFilters: true,
    },
    {
      Header: "Actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link to={`edit/${row.values.id}`}>Edit</Link>,
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return (
    <Card
      title="SECTION LIST"
      extra={
        <AppControl
          control="button"
          type="reset"
          name="Add New Section"
          size="full"
          color="orange"
          icon={<SiGoogleclassroom className="inline mr-1" />}
          onClick={() => {
            navigate("/section/add");
          }}
        />
      }
    >
      <AppControl control="table" columns={COLUMNS} data={sectionList} />
    </Card>
  );
};

export default Section;
