import React from "react";
import { useSelector } from "react-redux";
import { FieldArray } from "formik";
import AppControl from "../../components/";

const GuardianInfo = () => {
  const pages = useSelector((state) => state.common.pages);
  const currentPage =
    pages && pages.filter((item) => item.formName === "GuardianInfo");
  const currentPageControls = currentPage && currentPage[0].fields;

  const guardianRelation = ["Father's Details ", "Mother's Details "];

  let options = {};
  options["qualification"] = [
    {
      value: "Below Matriculation",
      label: "Below Matriculation",
    },
    {
      value: "Matriculation",
      label: "Matriculation",
    },
    {
      value: "Higher Secondary",
      label: "Higher Secondary",
    },
    {
      value: "Graduate",
      label: "Graduate",
    },
    {
      value: "Post Graduate",
      label: "Post Graduate",
    },
    {
      value: "Professional",
      label: "Professional",
    },
  ];

  return (
    <FieldArray name="guardians">
      {(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values } = form;
        const { guardians } = values;
        // console.log('fieldArrayProps', fieldArrayProps)
        // console.log('Form errors', form.errors)
        guardians[0].relation = "Father";
        guardians[1].relation = "Mother";
        return (
          <>
            {guardians &&
              guardians.map((guardian, index) => (
                <div key={index}>
                  <div className="mb-2 mt-4 font-bold">
                    <label htmlFor={`lblGuardian[${index}]`}>
                      {guardianRelation[index]}
                    </label>
                  </div>
                  <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                    {currentPageControls &&
                      currentPageControls.map((control) => (
                        <AppControl
                          key={`guardians[${index}].${control.fieldId}`}
                          control={control.type}
                          label={control.label}
                          name={`guardians[${index}].${control.fieldId}`}
                          placeholder={control.placeholder}
                          options={control.options && options[control.fieldId]}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
};

export default GuardianInfo;
