import React from "react";
import { useSelector } from "react-redux";
import AppControl from "../../components/";

const PreviousSchool = () => {
  const pages = useSelector((state) => state.common.pages);
  const currentPage =
    pages && pages.filter((item) => item.formName === "PreviousSchool");
  const currentPageControls = currentPage && currentPage[0].fields;

  return (
    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
      {currentPageControls &&
        currentPageControls.map((control) => (
          <AppControl
            key={control.fieldId}
            control={control.type}
            label={control.label}
            name={control.fieldId}
            placeholder={control.placeholder}
            //options={control.options && options[control.fieldId]}
          />
        ))}
    </div>
  );
};

export default PreviousSchool;
