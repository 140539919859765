import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthenticateRoute from "./AuthenticateRoute";
import PrivateRoute from "./PrivateRoute";
import Layout from "../layouts/index";
import Login from "../pages/Login.jsx";
import Welcome from "../pages/Welcome";
import Profile from "../pages/Profile";
import Unauthorised from "../pages/Unauthorised";
import ForgotPassword from "../pages/ForgotPassword.jsx";
import Register from "../pages/Register";
import Home from "../pages/Home.jsx";
import User from "../pages/user-management/user/User.jsx";
import UserAdd from "../pages/user-management/user/UserAdd.jsx";
import Role from "../pages/user-management/role/Role";
import RoleAdd from "../pages/user-management/role/RoleAddEdit";
import Branch from "../pages/branch-management/Branch";
import BranchAddEdit from "../pages/branch-management/BranchAddEdit";
import AcademicYear from "../pages/academic-management/AcademicYear";
import AcademicYearAddEdit from "../pages/academic-management/AcademicYearAddEdit";
import AcademicClass from "../pages/academic-management/AcademicClass";
import AcademicClassAddEdit from "../pages/academic-management/AcademicClassAddEdit";
import Section from "../pages/academic-management/Section";
import SectionAddEdit from "../pages/academic-management/SectionAddEdit";
import StudentAddEdit from "../pages/student-management/StudentAddEdit";
import StudentList from "../pages/student-management/StudentList";

const RouteConfig = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  console.log(isLoggedIn);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/welcome"
            element={
              <Layout>
                <Welcome />
              </Layout>
            }
          />
          <Route path="/unauthorised" element={<Unauthorised />} />
          <Route element={<PrivateRoute isAllowed={isLoggedIn} />}>
            <Route
              path="profile"
              element={
                <Layout>
                  <Profile />
                </Layout>
              }
            />
            <Route
              path="dashboard"
              element={
                <AuthenticateRoute
                  permission="Permissions.Dashboard.View"
                  redirectPath="/welcome"
                >
                  <Layout>
                    <Home />
                  </Layout>
                </AuthenticateRoute>
              }
            />
            <Route
              path="users"
              element={
                <AuthenticateRoute permission="Permissions.User.View">
                  <Layout>
                    <User />
                  </Layout>
                </AuthenticateRoute>
              }
            />
            <Route
              path="users/add"
              element={
                <Layout>
                  <UserAdd />
                </Layout>
              }
            />
            <Route
              path="users/edit/:id"
              element={
                <Layout>
                  <UserAdd />
                </Layout>
              }
            />
            <Route
              path="roles"
              element={
                <Layout>
                  <Role />
                </Layout>
              }
            />
            <Route
              path="role-add"
              element={
                <Layout>
                  <RoleAdd />
                </Layout>
              }
            />
            <Route
              path="role-edit/:id"
              element={
                <Layout>
                  <RoleAdd />
                </Layout>
              }
            />
          </Route>
          <Route
            path="branches"
            element={
              <AuthenticateRoute permission="Permissions.Branch.View">
                <Layout>
                  <Branch />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="branch/add"
            element={
              <AuthenticateRoute permission="Permissions.Branch.View">
                <Layout>
                  <BranchAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="session"
            element={
              <AuthenticateRoute permission="Permissions.Session.View">
                <Layout>
                  <AcademicYear />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="session/add"
            element={
              <AuthenticateRoute permission="Permissions.Session.Add">
                <Layout>
                  <AcademicYearAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="session/edit/:id"
            element={
              <AuthenticateRoute permission="Permissions.Session.Update">
                <Layout>
                  <AcademicYearAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="class"
            element={
              <AuthenticateRoute permission="Permissions.Class.View">
                <Layout>
                  <AcademicClass />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="class/add"
            element={
              <AuthenticateRoute permission="Permissions.Class.Add">
                <Layout>
                  <AcademicClassAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="class/edit/:id"
            element={
              <AuthenticateRoute permission="Permissions.Class.Update">
                <Layout>
                  <AcademicClassAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="section"
            element={
              <AuthenticateRoute permission="Permissions.Section.View">
                <Layout>
                  <Section />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="section/add"
            element={
              <AuthenticateRoute permission="Permissions.Section.Add">
                <Layout>
                  <SectionAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="section/edit/:id"
            element={
              <AuthenticateRoute permission="Permissions.Section.Update">
                <Layout>
                  <SectionAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="admission/add"
            element={
              <AuthenticateRoute permission="Permissions.Admission.Add">
                <Layout>
                  <StudentAddEdit />
                </Layout>
              </AuthenticateRoute>
            }
          />
          <Route
            path="students"
            element={
              <AuthenticateRoute permission="Permissions.Student.View">
                <Layout>
                  <StudentList />
                </Layout>
              </AuthenticateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default RouteConfig;
