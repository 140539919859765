import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { Select } from "antd";

function SelectWithSearch(props) {
  const { label, name, placeholder, options, ...rest } = props;

  return (
    <div className="mb-1">
      <div className="mb-1">
        <label htmlFor={name}>{label}</label>
      </div>
      <Field name={name}>
        {({ field, form }) => {
          const { value } = field;
          var defaultValue = value;
          const handleChange = (value) => {
            form.setFieldValue(name, value);
          };
          return (
            <Select
              placeholder={placeholder}
              style={{
                width: "100%",
              }}
              status={form.errors[name] && form.touched[name] ? "error" : ""}
              options={options}
              value={defaultValue}
              onChange={handleChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            ></Select>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectWithSearch;
