import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  RiShieldUserFill,
  RiDashboardLine,
  RiGitBranchLine,
} from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { FaUniversity } from "react-icons/fa";
import { SiMicrosoftacademic } from "react-icons/si";
import data from "./NavItems.json";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(null);
  const { permissions } = useSelector((state) => state.auth);
  //console.log(current);
  if (!current) setCurrent(location.pathname.substring(1));

  const icons = {
    RiDashboardLine: <RiDashboardLine />,
    RiShieldUserFill: <RiShieldUserFill />,
    RiGitBranchLine: <RiGitBranchLine />,
    HiUserGroup: <HiUserGroup />,
    FaUniversity: <FaUniversity />,
    SiMicrosoftacademic: <SiMicrosoftacademic />,
  };
  // console.log(permission);
  let authorisedMenu = [];
  let findValue = (arr, authArr) => {
    return arr.map((menu) => {
      if (menu.children) {
        let childMenu = [];
        // console.log("child");
        var parent = {
          label: menu.label,
          key: menu.key,
          children: childMenu,
          icon: icons[menu.image],
        };
        if (permissions && permissions.some((ai) => menu.auth.includes(ai)))
          authArr.push(parent);
        findValue(menu.children, childMenu);
      } else {
        menu.icon = icons[menu.image];
        if (permissions && permissions.includes(menu.auth)) authArr.push(menu);
      }
    });
  };
  if (data.length > 0) findValue(data, authorisedMenu);
  const onClick = (e) => {
    //console.log("click ", e);
    navigate("/" + e.key);
    setCurrent(e.key);
  };
  return (
    <Menu
      mode="horizontal"
      items={authorisedMenu}
      onClick={onClick}
      selectedKeys={current}
    ></Menu>
  );
};

export default NavBar;
