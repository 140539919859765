import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { FaUserCircle, FaUserCog } from "react-icons/fa";
import { profile, logoutUser } from "../../store/slice/auth";
import { fetchFormElements } from "../../store/slice/common";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fullname = useSelector((state) => state.auth.fullname);
  const user = useSelector((state) => state.auth.user);
  const url = user
    ? `http://194.59.165.177:18002/ProfileImages/Thumbs/${user.profilePicture}`
    : null;

  useEffect(() => {
    dispatch(profile());
    dispatch(fetchFormElements());
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      dispatch(logoutUser());
      navigate("/login");
    }
    if (e.key === "profile") {
      navigate("/profile");
    }
  };
  const items = [
    {
      label: fullname,
      key: "0",
      disabled: true,
    },
    {
      label: "Profile",
      key: "profile",
      icon: <FaUserCircle />,
    },
    {
      label: "Settings",
      key: "2",
      icon: <FaUserCog />,
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "3",
      icon: <LogoutOutlined />,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <nav className="relative w-full flex flex-wrap items-center justify-between py-3 text-orange-500 hover:text-orange-600 focus:text-orange-600 shadow-lg">
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <span className="text-2xl font-bold">Vidyakalpa</span>
        <div className="flex items-center justify-end">
          <span className="relative cursor-pointer inline-block mr-5">
            <svg
              className="w-6 h-6 text-gray-700 fill-current"
              viewBox="0 0 20 20"
            >
              <path
                d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
            <span className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"></span>
          </span>
          <Dropdown menu={menuProps}>
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                className="cursor-pointer"
                size="large"
                shape="square"
                //src={url}
                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                <p className="font-bold">
                  {user &&
                    user.firstName.charAt(0) + " " + user.lastName.charAt(0)}
                </p>
              </Avatar>
            </a>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};
export default Header;
