import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../client";

export const getAcademicClasses = createAsyncThunk(
  "AcademicClass/List",
  async (thunkAPI) => {
    const url = "class";
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getClassCategory = createAsyncThunk(
  "ClassCategory/List",
  async (thunkAPI) => {
    const url = "class/category";
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getClassesByBranch = createAsyncThunk(
  "Branch/Class",
  async (id, thunkAPI) => {
    const url = `class/branch/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getAcademicClass = createAsyncThunk(
  "User",
  async (id, thunkAPI) => {
    const url = `class/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const saveAcademicClass = createAsyncThunk(
  "AcademicClass/Save",
  async (academicclass, thunkAPI) => {
    const url = "class";
    return api.post(url, academicclass).then(function (response) {
      return response.data;
    });
  }
);
export const updateAcademicClass = createAsyncThunk(
  "AcademicClass/Update",
  async (academicclass, thunkAPI) => {
    const url = `class/${academicclass.id}`;
    return api.put(url, academicclass).then(function (response) {
      return response.data;
    });
  }
);
export const deleteBranch = createAsyncThunk(
  "User/delete",
  async (id, thunkAPI) => {
    const url = `user/${id}`;
    return api.delete(url).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  listBox: [],
  categories: [],
  academicClass: null,
  isLoading: false,
  message: null,
};

const slice = createSlice({
  name: "academicclasses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAcademicClasses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAcademicClasses.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAcademicClasses.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getClassCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getClassCategory.fulfilled, (state, action) => {
        state.categories = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getClassCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getClassesByBranch.pending, (state, action) => {
        state.isLoading = true;
        state.list = [];
      })
      .addCase(getClassesByBranch.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
        let classes = [];
        action.payload.data.forEach((item) => {
          classes.push({
            label: item.className,
            value: item.id,
          });
        });
        state.listBox = classes;
      })
      .addCase(getClassesByBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getAcademicClass.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAcademicClass.fulfilled, (state, action) => {
        state.academicClass = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAcademicClass.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(saveAcademicClass.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(saveAcademicClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(saveAcademicClass.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(updateAcademicClass.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateAcademicClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(updateAcademicClass.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //   .addCase(deleteUser.pending, (state, action) => {
      //     state.isLoading = true;
      //     state.message = "";
      //   })
      //   .addCase(deleteUser.fulfilled, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload.message;
      //   })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default slice.reducer;
