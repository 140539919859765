import React, { useEffect } from "react";
import { Button, Typography } from "antd";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiFolderUserLine } from "react-icons/ri";
import { FiCheckCircle } from "react-icons/fi";
import AppControl from "../../../components";
import Card from "../../../components/controls/Card";
import { getRoles } from "../../../store/slice/roles";
import CardRole from "../../../components/controls/CardRole";

function Role() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoles());
  }, []);
  const roleList = useSelector((state) => state.roles.list);

  return (
    <Card
      title="ROLE LIST"
      extra={
        <AppControl
          control="button"
          type="reset"
          name="Add New Role"
          size="full"
          color="orange"
          icon={<RiFolderUserLine size="1.25em" className="inline mr-1" />}
          onClick={() => {
            navigate("/role-add");
          }}
        />
      }
    >
      <div className="flex flex-wrap justify-start gap-6">
        {roleList.map((item, index) => {
          return (
            <CardRole title={item.name} key={item.id}>
              {/* <div className="font-semibold mb-3 ">
                Total users with this role: {item.claims.length}
              </div> */}
              <ul className="list-none h-48">
                <IconContext.Provider
                  value={{
                    color: "green",
                  }}
                >
                  {item.claims.slice(0, 5).map((claim, index) => {
                    return (
                      <li key={index} className="mb-3">
                        <FiCheckCircle className="inline mr-3" />

                        {claim.value}
                      </li>
                    );
                  })}
                  {item.claims.length > 5 ? (
                    <li>
                      <FiCheckCircle className="inline mr-3" />
                      and {item.claims.length - 5} more...
                    </li>
                  ) : null}
                </IconContext.Provider>
              </ul>
              <div className="relative h-14 w-full ...">
                <div className="border-t-2 border-neutral-100 absolute inset-x-0 bottom-0 pt-3 grid justify-items-end">
                  <Button
                    onClick={() => {
                      navigate("/role-edit/" + item.id);
                    }}
                  >
                    Edit Role
                  </Button>
                </div>
              </div>
            </CardRole>
          );
        })}
      </div>
    </Card>
  );
}

export default Role;
