import React, { useEffect } from "react";
import { App } from "antd";
import Auxiliary from "../../utilities/Auxiliary";
import Loader from "./Loader";

const AppNotificationContainer = ({ loading, msg, error }) => {
  const { message } = App.useApp();

  useEffect(() => {
    if (error) message.error(error);
  }, [error, msg]);

  return <Auxiliary>{loading && <Loader />}</Auxiliary>;
};

export default AppNotificationContainer;
