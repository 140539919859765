import React from "react";

const CardRole = (props) => {
  const { title } = props;
  return (
    <div className="block w-72 h-80 rounded-lg bg-white shadow-lg">
      <div className="py-3 px-6">
        <h5 className="text-lg font-bold leading-tight text-neutral-800">
          {title}
        </h5>
      </div>
      <div className="p-3">{props.children}</div>
    </div>
  );
};

export default CardRole;
