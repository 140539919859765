import React, { useEffect } from "react";
import { Button, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";
import AppControl from "../../components";
import Card from "../../components/controls/Card";
import { getStudents } from "../../store/slice/students";

const StudentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudents());
  }, []);
  const studentList = useSelector((state) => state.students.list);

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
      isVisible: false,
    },
    {
      Header: "Branch",
      accessor: "branches[0].branchName",
    },
    // {
    //   Header: "Session",
    //   accessor: "sessionId",
    //   isVisible: false,
    // },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Middle Name",
      accessor: "middleName",
      isVisible: false,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Roll",
      accessor: "rollNo",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "DOB",
      accessor: "dateOfBirth",
    },
    {
      Header: "DOA",
      accessor: "dateOfAdmission",
    },
    {
      Header: "Blood Group",
      accessor: "bloodGroup",
    },
    {
      Header: "Nationality",
      accessor: "nationality",
    },
    {
      Header: "Caste",
      accessor: "caste",
    },
    {
      Header: "Sub Caste",
      accessor: "subCaste",
    },
    {
      Header: "Certificate Number",
      accessor: "certificateNumber",
      isVisible: false,
    },
    {
      Header: "Certificate Issued By",
      accessor: "certificateIssuedBy",
      isVisible: false,
    },
    {
      Header: "Mother Tongue",
      accessor: "motherTongue",
    },
    {
      Header: "Religion",
      accessor: "religion",
    },
    {
      Header: "PWD",
      accessor: "pwd",
    },
    {
      Header: "PWD Percentage",
      accessor: "pwdpercentage",
      isVisible: false,
    },
    {
      Header: "PWD Description",
      accessor: "pwddescription",
      isVisible: false,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Aadhar",
      accessor: "aadhar",
    },
    {
      Header: "Photo",
      accessor: "photo",
      isVisible: false,
    },
    {
      Header: "Signature",
      accessor: "signature",
      isVisible: false,
    },
    {
      Header: "Previous School Name",
      accessor: "previousSchoolName",
      isVisible: false,
    },
    {
      Header: "Previous School Address",
      accessor: "previousSchoolAddress",
      isVisible: false,
    },
    {
      Header: "TC NO",
      accessor: "tcno",
      isVisible: false,
    },
    {
      Header: "TC Date",
      accessor: "tcdate",
      isVisible: false,
    },
    {
      Header: "Father Name",
      accessor: "guardians[0].fullName",
    },
    {
      Header: "Father Mobile",
      accessor: "guardians[0].mobile",
      isVisible: false,
    },
    {
      Header: "Father Email",
      accessor: "guardians[0].email",
      isVisible: false,
    },
    {
      Header: "Father Aadhar",
      accessor: "guardians[0].aadhar",
      isVisible: false,
    },
    {
      Header: "Father Voter Id",
      accessor: "guardians[0].voter",
      isVisible: false,
    },
    {
      Header: "Father Occupation",
      accessor: "guardians[0].occupation",
      isVisible: false,
    },
    {
      Header: "Father Qualification",
      accessor: "guardians[0].qualification",
      isVisible: false,
    },
    {
      Header: "Father Annual Income",
      accessor: "guardians[0].annualIncome",
      isVisible: false,
    },
    {
      Header: "Mother Name",
      accessor: "guardians[1].fullName",
    },
    {
      Header: "Mother Mobile",
      accessor: "guardians[1].mobile",
      isVisible: false,
    },
    {
      Header: "Mother Email",
      accessor: "guardians[1].email",
      isVisible: false,
    },
    {
      Header: "Mother Aadhar",
      accessor: "guardians[1].aadhar",
      isVisible: false,
    },
    {
      Header: "Mother Voter Id",
      accessor: "guardians[1].voter",
      isVisible: false,
    },
    {
      Header: "Mother Occupation",
      accessor: "guardians[1].occupation",
      isVisible: false,
    },
    {
      Header: "Mother Qualification",
      accessor: "guardians[1].qualification",
      isVisible: false,
    },
    {
      Header: "Mother Annual Income",
      accessor: "guardians[1].annualIncome",
      isVisible: false,
    },
    {
      Header: "Present stateCode",
      accessor: "addresses[0].stateCode",
      isVisible: false,
    },
    {
      Header: "Present districtCode",
      accessor: "addresses[0].districtCode",
      isVisible: false,
    },
    {
      Header: "Present Village/Town",
      accessor: "addresses[0].villageTown",
      isVisible: false,
    },
    {
      Header: "Present PO",
      accessor: "addresses[0].postOffice",
      isVisible: false,
    },
    {
      Header: "Present Municipality",
      accessor: "addresses[0].municipality",
      isVisible: false,
    },
    {
      Header: "Present Block",
      accessor: "addresses[0].block",
      isVisible: false,
    },
    {
      Header: "Present PS",
      accessor: "addresses[0].policeStation",
      isVisible: false,
    },
    {
      Header: "Present Pin Code",
      accessor: "addresses[0].pinCode",
      isVisible: false,
    },
    {
      Header: "Permanent stateCode",
      accessor: "addresses[1].stateCode",
      isVisible: false,
    },
    {
      Header: "Permanent districtCode",
      accessor: "addresses[1].districtCode",
      isVisible: false,
    },
    {
      Header: "Permanent Village/Town",
      accessor: "addresses[1].villageTown",
      isVisible: false,
    },
    {
      Header: "Permanent PO",
      accessor: "addresses[1].postOffice",
      isVisible: false,
    },
    {
      Header: "Permanent Municipality",
      accessor: "addresses[1].municipality",
      isVisible: false,
    },
    {
      Header: "Permanent Block",
      accessor: "addresses[1].block",
      isVisible: false,
    },
    {
      Header: "Permanent PS",
      accessor: "addresses[1].policeStation",
      isVisible: false,
    },
    {
      Header: "Permanent Pin Code",
      accessor: "addresses[1].pinCode",
      isVisible: false,
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link to={`edit/${row.values.id}`}>Edit</Link>,
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return (
    <Card title="STUDENT LIST">
      <AppControl control="table" columns={COLUMNS} data={studentList} />
    </Card>
  );
};

export default StudentList;
