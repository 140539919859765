import React, { useState, useEffect } from "react";
import { Button, Card, Dropdown, Modal, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiUserAdd, HiOutlineChevronDown } from "react-icons/hi";
import AppControl from "../../../components";
import { getUsers, deleteUser } from "../../../store/slice/users";

function User() {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  const userList = useSelector((state) => state.users.list);

  const showDeleteConfirm = (row) => {
    setModalText(`Are you sure you want to delete ${row.firstName}?`);
    setOpen(true);
    setDeleteId(row.id);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    console.log(deleteId);
    const resultAction = await dispatch(deleteUser(deleteId));
    console.log("resultAction", resultAction);
    if (deleteUser.fulfilled.match(resultAction)) {
      setOpen(false);
      setConfirmLoading(false);
      Modal.success({
        content: resultAction.payload.message,
      });
    } else {
      setOpen(false);
      setConfirmLoading(false);
      Modal.error({
        content: resultAction.error.message,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
      isVisible: false,
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true,
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      disableFilters: true,
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      disableFilters: true,
    },
    // {
    //   Header: "Branch",
    //   accessor: "schoolName",
    // },
    {
      Header: "Actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link to={`edit/${row.values.id}`}>Edit</Link>,
              },
              {
                key: "2",
                danger: true,
                label: (
                  <span onClick={() => showDeleteConfirm(row.values)}>
                    Delete
                  </span>
                ),
              },
              {
                key: "3",
                label: "test",
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return (
    <Card
      title="USER LIST"
      extra={
        <AppControl
          control="button"
          type="reset"
          name="Add New User"
          size="full"
          color="orange"
          icon={<HiUserAdd size="1.25em" className="inline mr-1" />}
          onClick={() => {
            navigate("/users/add");
          }}
        />
      }
    >
      <AppControl control="table" columns={COLUMNS} data={userList} />
      <Modal
        title="Delete"
        open={open}
        okText="Yes, Delete!"
        okType="danger"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        cancelText="No, Cancel"
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </Card>
  );
}

export default User;
