import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function DatePickerField(props) {
  const { label, name, ...rest } = props;
  return (
    <Field name={name}>
      {({ form, field }) => {
        const { value } = field;
        var defaultValue = value;
        const onChange = (date, dateString) => {
          console.log(date, dateString);
          form.setFieldValue(name, date);
        };
        return (
          <div>
            <div className="mb-1">
              <label htmlFor={name}>{label}</label>
            </div>
            <div className="mb-1">
              <DatePicker
                id={name}
                format={"DD/MM/YYYY"}
                value={defaultValue && dayjs(defaultValue)}
                onChange={onChange}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <ErrorMessage component={TextError} name={name} />
          </div>
        );
      }}
    </Field>
  );
}

export default DatePickerField;
