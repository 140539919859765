import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../client";

export const getAcademicYears = createAsyncThunk(
  "AcademicYear/List",
  async (thunkAPI) => {
    const url = "academicyear";
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getAcademicYearsByBranch = createAsyncThunk(
  "Branch/AcademicYears",
  async (id, thunkAPI) => {
    const url = `academicyear/branch/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getAcademicYear = createAsyncThunk(
  "AcademicYear",
  async (id, thunkAPI) => {
    const url = `academicyear/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const saveAcademicYear = createAsyncThunk(
  "AcademicYear/Save",
  async (academicyear, thunkAPI) => {
    const url = "academicyear";
    return api.post(url, academicyear).then(function (response) {
      return response.data;
    });
  }
);
export const updateAcademicYear = createAsyncThunk(
  "AcademicYear/Update",
  async (academicyear, thunkAPI) => {
    const url = `academicyear/${academicyear.id}`;
    return api.put(url, academicyear).then(function (response) {
      return response.data;
    });
  }
);
export const deleteBranch = createAsyncThunk(
  "User/delete",
  async (id, thunkAPI) => {
    const url = `user/${id}`;
    return api.delete(url).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  listBox: [],
  academicYear: null,
  isLoading: false,
  message: null,
};

const slice = createSlice({
  name: "academicyears",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAcademicYears.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAcademicYears.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAcademicYears.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getAcademicYearsByBranch.pending, (state, action) => {
        state.isLoading = true;
        state.list = [];
      })
      .addCase(getAcademicYearsByBranch.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
        let academicYears = [];
        action.payload.data.forEach((item) => {
          academicYears.push({
            label: item.sessionName,
            value: item.id,
          });
        });
        state.listBox = academicYears;
      })
      .addCase(getAcademicYearsByBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getAcademicYear.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAcademicYear.fulfilled, (state, action) => {
        state.academicYear = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(saveAcademicYear.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(saveAcademicYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(saveAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(updateAcademicYear.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateAcademicYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(updateAcademicYear.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      //   .addCase(deleteUser.pending, (state, action) => {
      //     state.isLoading = true;
      //     state.message = "";
      //   })
      //   .addCase(deleteUser.fulfilled, (state, action) => {
      //     state.isLoading = false;
      //     state.message = action.payload.message;
      //   })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default slice.reducer;
