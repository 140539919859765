import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../client";

export const getUsers = createAsyncThunk("User/list", async (thunkAPI) => {
  const url = "user";
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const getUser = createAsyncThunk("User", async (id, thunkAPI) => {
  const url = `user/${id}`;
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const saveUser = createAsyncThunk(
  "User/save",
  async (user, thunkAPI) => {
    const url = "user";
    return api.post(url, user).then(function (response) {
      return response.data;
    });
  }
);
export const updateUser = createAsyncThunk(
  "User/update",
  async (user, thunkAPI) => {
    const url = `user/${user.id}`;
    return api.put(url, user).then(function (response) {
      return response.data;
    });
  }
);
export const deleteUser = createAsyncThunk(
  "User/delete",
  async (id, thunkAPI) => {
    const url = `user/${id}`;
    return api.delete(url).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  user: null,
  isLoading: false,
  message: null,
  lastFetch: null,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
        state.lastFetch = Date.now();
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(saveUser.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default slice.reducer;
