import { combineReducers } from "redux";
//import { connectRouter } from "connected-react-router";
import Auth from "./slice/auth";
import Users from "./slice/users";
import Roles from "./slice/roles";
import Common from "./slice/common";
import branches from "./slice/branches";
import academicyears from "./slice/academicyears";
import academicclasses from "./slice/academicclasses";
import sections from "./slice/sections";
import students from "./slice/students";

const createRootReducer = (history) =>
  combineReducers({
    //router: connectRouter(history),
    auth: Auth,
    users: Users,
    roles: Roles,
    common: Common,
    branches,
    academicyears,
    academicclasses,
    sections,
    students,
  });

export default createRootReducer;
