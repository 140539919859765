import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../client";

export const getSections = createAsyncThunk(
  "Section/List",
  async (thunkAPI) => {
    const url = "section";
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getSectionsByClass = createAsyncThunk(
  "Section/Class",
  async (id, thunkAPI) => {
    const url = `section/class/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const getSection = createAsyncThunk("Section", async (id, thunkAPI) => {
  const url = `section/${id}`;
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const saveSection = createAsyncThunk(
  "Section/Save",
  async (section, thunkAPI) => {
    const url = "section";
    return api.post(url, section).then(function (response) {
      return response.data;
    });
  }
);
export const updateSection = createAsyncThunk(
  "Section/Update",
  async (section, thunkAPI) => {
    const url = `section/${section.id}`;
    return api.put(url, section).then(function (response) {
      return response.data;
    });
  }
);
export const deleteSection = createAsyncThunk(
  "Section/Delete",
  async (id, thunkAPI) => {
    const url = `section/${id}`;
    return api.delete(url).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  listBox: [],
  section: null,
  isLoading: false,
  message: null,
};

const slice = createSlice({
  name: "sections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getSections.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getSectionsByClass.pending, (state, action) => {
        state.isLoading = true;
        state.list = [];
      })
      .addCase(getSectionsByClass.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.isLoading = false;
        let sections = [];
        action.payload.data.forEach((item) => {
          sections.push({
            label: item.sectionName,
            value: item.id,
          });
        });
        state.listBox = sections;
      })
      .addCase(getSectionsByClass.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getSection.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSection.fulfilled, (state, action) => {
        state.section = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getSection.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(saveSection.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(saveSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(saveSection.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(updateSection.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(updateSection.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(deleteSection.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export default slice.reducer;
