import React from "react";
import { useSelector } from "react-redux";
import { FieldArray } from "formik";
import AppControl from "../../components/";

const ResidentialInfo = (props) => {
  const { label, name, placeholder, ...rest } = props;
  const { pages, states, districts } = useSelector((state) => state.common);
  const currentPage =
    pages && pages.filter((item) => item.formName === "ResidentialInfo");
  const currentPageControls = currentPage && currentPage[0].fields;

  const addressType = ["Present Adress ", "Permanent Adress "];

  let options = {};
  if (states) {
    let stateCodes = [];
    let districtCodes = [];
    states &&
      states.forEach(function (item, index) {
        stateCodes.push({
          label: item.stateName,
          value: item.gstStateCode,
        });
      });
    districts &&
      districts.forEach(function (item, index) {
        districtCodes.push({
          label: item.districtName,
          value: item.districtCode,
        });
      });
    options["stateCode"] = stateCodes;
    options["districtCode"] = districtCodes;
  }
  // console.log("options", options);
  return (
    <FieldArray name="addresses">
      {(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values } = form;
        const { addresses } = values;
        // console.log('fieldArrayProps', fieldArrayProps)
        // console.log('Form errors', form.errors)
        return (
          <>
            {addresses &&
              addresses.map((adress, index) => (
                <div key={index}>
                  <div className="mb-2 mt-4 font-bold">
                    <label htmlFor={`lblAddress[${index}]`}>
                      {addressType[index]}
                    </label>
                  </div>
                  {index > 0 && (
                    <AppControl
                      key={`addresses[${index}].isSame`}
                      control="checkbox"
                      name={`addresses[${index}].isSame`}
                      label="Same as Present Address"
                    />
                  )}
                  <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                    {currentPageControls &&
                      states &&
                      currentPageControls.map((control) => (
                        <AppControl
                          key={`addresses[${index}].${control.fieldId}`}
                          control={control.type}
                          label={control.label}
                          name={`addresses[${index}].${control.fieldId}`}
                          placeholder={control.placeholder}
                          options={control.options && options[control.fieldId]}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
};

export default ResidentialInfo;
