import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Button, Dropdown, InputNumber, Select, Space } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFileCsv, FaRegFilePdf } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi";
import Papa from "papaparse";
import JsPDF from "jspdf";
import "jspdf-autotable";
import "./table.css";
import { BranchFilter } from "./BranchFilter";
import { GlobalFilter } from "./GlobalFilter";
import { useExportData } from "./useExportData";

const Option = Select.Option;
var XLSX = require("xlsx");
function getExportFileBlob({ columns, data, fileType, fileName }) {
  console.log(fileType);
  if (fileType === "csv") {
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  } else if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        minCellWidth: 20,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

const Table = (props) => {
  const columns = props.columns;
  const data = props.data;
  const hiddenColumnIds = [];

  Array.from(columns).forEach((col) => {
    if (col.isVisible === false) hiddenColumnIds.push(col.accessor);
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    exportData,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumnIds,
        pageIndex: 0,
        pageSize: 10,
      },
      getExportFileBlob,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div className="flex justify-between">
        {/* <BranchFilter /> */}
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <Dropdown
          menu={{
            items: [
              {
                key: "csv",
                label: "Csv",
                icon: <FaFileCsv size={15} color="green" />,
              },
              {
                key: "xlsx",
                label: "Excel",
                icon: <SiMicrosoftexcel size={15} color="green" />,
              },
              {
                key: "pdf",
                label: "Pdf",
                icon: <FaRegFilePdf size={15} color="red" />,
              },
            ],
            onClick: (e) => exportData(e.key, true),
          }}
        >
          <Button>
            <Space>
              Export
              <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      </div>
      <div className="my-3" style={{ overflowX: "auto" }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ul className="flex justify-around items-center">
        <li>
          <span className="inline-block align-middle">
            Showing {pageIndex * pageSize + 1} -{" "}
            {pageIndex * pageSize + pageSize < data.length
              ? pageIndex * pageSize + pageSize
              : data.length}{" "}
            of {data.length} items
          </span>
        </li>
        <li title="First Page">
          <Button disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
            {"<<"}
          </Button>
        </li>
        <li title="Previous Page">
          <Button disabled={!canPreviousPage} onClick={() => previousPage()}>
            {"<"}
          </Button>
        </li>
        <li title="Next Page">
          <Button disabled={!canNextPage} onClick={() => nextPage()}>
            {">"}
          </Button>
        </li>
        <li title="Last Page">
          <Button
            disabled={!canNextPage}
            onClick={() => gotoPage(pageCount - 1)}
          >
            {">>"}
          </Button>
        </li>
        <li>
          <span className="inline-block align-middle mx-2">Go to</span>
          <InputNumber
            min={1}
            max={pageOptions.length}
            style={{ width: 50 }}
            defaultValue={pageIndex + 1}
            value={pageIndex + 1}
            onChange={(value) => {
              const pageNumber = value ? Number(value) - 1 : 0;
              gotoPage(pageNumber);
            }}
          />
          <span className="inline-block align-middle mx-2">Page</span>
        </li>
        <li>
          <Select
            value={pageSize}
            onChange={(value) => setPageSize(Number(value))}
          >
            {[10, 20, 30, 50].map((pageSize) => (
              <Option key={pageSize} value={pageSize}>
                Show {pageSize}
              </Option>
            ))}
          </Select>
        </li>
      </ul>
    </>
  );
};

export default Table;
