import { applyMiddleware, createStore } from "redux";
//import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import createRootReducer from "./reducer";

// const createBrowserHistory = require("history").createBrowserHistory;
// export const history = createBrowserHistory();
//const routeMiddleware = routerMiddleware(history);
//const middlewares = [thunk, routeMiddleware];
const middlewares = [thunk];
//const preloadedState = {};
export default function configureStore(preloadedState = {}) {
  return createStore(
    createRootReducer(), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        //routerMiddleware(history), // for dispatching history actions
        ...middlewares
      )
    )
  );
}
