import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthenticateRoute = ({
  permission,
  redirectPath = "/unauthorised",
  children,
}) => {
  const { permissions } = useSelector((state) => state.auth);
  if (permissions && permissions.includes(permission)) {
    return children ? children : <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};
export default AuthenticateRoute;
