import React from "react";
import { Field, ErrorMessage } from "formik";
import { Checkbox } from "antd";
import TextError from "./TextError";

function checkbox(props) {
  const { label, name, placeholder, ...rest } = props;

  return (
    <div>
      <div className="mb-2">
        <Field name={name}>
          {({ field, form }) => {
            const { value } = field;
            var checked = value;
            const onChange = (e) => {
              form.setFieldValue(name, e.target.checked);
            };

            return (
              <Checkbox id={name} checked={checked} onChange={onChange}>
                {label}
              </Checkbox>
            );
          }}
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default checkbox;
