import React, { useEffect, useState } from "react";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useFormikContext, Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../../components/";
import Card from "../../components/controls/Card";
import { createYupSchema } from "../../utilities/";
import {
  getStates,
  getDistricts,
  getDistrictsByState,
} from "../../store/slice/common";
import { saveBranch } from "../../store/slice/branches";

function FormItemValueChanged() {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values["stateCode"]) dispatch(getDistrictsByState(values["stateCode"]));
    console.log("values-stateCode-", values["stateCode"]);
  }, [values["stateCode"]]);
  return null;
}

function BranchAddEdit() {
  const [title, setTitle] = useState(null);
  let { id } = useParams();
  const { message } = App.useApp();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pages, states, districts } = useSelector((state) => state.common);
  const { isLoading, user } = useSelector((state) => state.users);

  const currentPage =
    pages && pages.filter((item) => item.formName === "BranchMaster");
  const currentPageControls = currentPage && currentPage[0].fields;

  useEffect(() => {
    dispatch(getStates());
    dispatch(getDistricts());
    if (id) {
      setTitle("EDIT BRANCH");
      //dispatch(getUser(id));
    } else {
      setTitle("CREATE A NEW BRANCH");
    }
  }, []);

  let initialValues = {},
    dropdownValues = {},
    yepSchema,
    validationSchema;
  if (currentPageControls) {
    if (id && user) {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = user[control.fieldId])
      );
    } else {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = control.defaultValue)
      );
      initialValues["owners"] = [""];
    }
    yepSchema = currentPageControls.reduce(createYupSchema, {});
    validationSchema = Yup.object().shape(yepSchema);
  }
  if (states) {
    let stateCodes = [];
    let districtCodes = [];
    states &&
      states.forEach(function (item, index) {
        stateCodes.push({
          label: item.stateName,
          value: item.gstStateCode,
        });
      });
    districts &&
      districts.forEach(function (item, index) {
        districtCodes.push({
          label: item.districtName,
          value: item.districtCode,
        });
      });
    dropdownValues["stateCode"] = stateCodes;
    dropdownValues["districtCode"] = districtCodes;
  }
  // console.log("user", user);
  console.log("initialValues", initialValues);
  const onSubmit = async (values) => {
    values.branchCode = "101";
    values.branchTypeId = 1;
    values.address = "";
    console.log("Form data", values);
    if (id) {
      values.applicationName = "Vidyakalpa";
      values.id = user.id;

      // const resultAction = await dispatch(updateUser(values));
      // console.log("resultAction", resultAction);
      // if (updateUser.fulfilled.match(resultAction)) {
      //   message.success(resultAction.payload.message);
      //   navigate("/users");
      // } else {
      //   if (resultAction.payload) {
      //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
      //     //formikHelpers.setErrors(resultAction.payload.field_errors)
      //   } else {
      //     message.error(`Save failed: ${resultAction.error.message}`);
      //   }
      // }
    } else {
      //values.userName = values.email;
      const resultAction = await dispatch(saveBranch(values));
      console.log("resultAction", resultAction);
      if (saveBranch.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/branches");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Card title={title}>
            <Form>
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                {currentPageControls &&
                  states &&
                  currentPageControls.map((control) => (
                    <AppControl
                      key={control.fieldId}
                      control={control.type}
                      label={control.label}
                      name={control.fieldId}
                      placeholder={control.placeholder}
                      options={
                        control.options && dropdownValues[control.fieldId]
                      }
                    />
                  ))}
              </div>
              <AppControl control="owner" name="owner" />
              <div className="border-t-2 border-neutral-100 pt-3">
                <AppControl
                  control="button"
                  type="submit"
                  name="Save"
                  size="md"
                  color="orange"
                  disabled={!formik.isValid || isLoading}
                  loading={isLoading}
                />
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="md"
                  color="blue"
                  onClick={() => {
                    navigate("/branches");
                  }}
                />
              </div>
              <FormItemValueChanged />
            </Form>
          </Card>
        );
      }}
    </Formik>
  );
}

export default BranchAddEdit;
