import React from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AppControl from "../components/";

function Profile() {
  const user = useSelector((state) => state.auth.user);
  console.log(user.profilePicture);
  const url = user
    ? `http://194.59.165.177:18002/ProfileImages/${user.profilePicture}`
    : null;
  let initialValues = {
    firstName: user && user.firstName,
    lastName: user && user.lastName,
  };
  return (
    <div className="p-6 shadow-lg rounded-lg text-gray-700">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        // validationSchema={validationSchema}
        // onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <h5 className="font-semibold text-3xl mb-5">Profile Details</h5>
              <hr className="my-6 border-gray-300" />
              <div className="form-container vertical">
                <div className="mb-3">
                  <h5>General</h5>
                  <p>
                    Basic info, like your name and address that will displayed
                    in public.
                  </p>
                </div>
                <AppControl
                  control="avatar"
                  label="Profile Photo"
                  name="avatar"
                  url={url}
                />
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2 py-5 border-b border-gray-200 items-center">
                  <AppControl
                    control="text"
                    //type="email"
                    label="First Name"
                    name="firstName"
                  />
                  <AppControl
                    control="text"
                    label="Last Name"
                    name="lastName"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Profile;
