import React from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import TextError from "./TextError";

function InputText(props) {
  const { label, name, placeholder, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <div>
          <div className="mb-1">
            <label htmlFor={name}>{label}</label>
          </div>
          <div className="mb-1">
            <Input
              placeholder={placeholder}
              id={name}
              name={name}
              status={form.errors[name] && form.touched[name] ? "error" : ""}
              {...rest}
              {...field}
            />
          </div>
          <ErrorMessage component={TextError} name={name} />
        </div>
      )}
    </Field>
  );
}

export default InputText;
