import React, { useEffect } from "react";
import { Button, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";
import { SiGoogleclassroom } from "react-icons/si";
import AppControl from "../../components";
import Card from "../../components/controls/Card";
import { getAcademicClasses } from "../../store/slice/academicclasses";

const AcademicClass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAcademicClasses());
  }, []);
  const classList = useSelector((state) => state.academicclasses.list);

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
      isVisible: false,
      disableFilters: true,
      disableExport: true,
    },
    {
      Header: "Branch",
      accessor: "branches[0].branchName",
    },
    {
      Header: "Category",
      accessor: "categories[0].categoryName",
      disableFilters: true,
    },
    {
      Header: "Class Name",
      accessor: "className",
      disableFilters: true,
    },
    {
      Header: "Sequence",
      accessor: "sequence",
      disableFilters: true,
    },
    {
      Header: "School Final",
      accessor: "schoolFinal",
      disableFilters: true,
    },
    {
      Header: "Subject Combination",
      accessor: "subjectCombination",
      disableFilters: true,
    },
    {
      Header: "Actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link to={`edit/${row.values.id}`}>Edit</Link>,
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return (
    <Card
      title="CLASS LIST"
      extra={
        <AppControl
          control="button"
          type="reset"
          name="Add New Class"
          size="full"
          color="orange"
          icon={<SiGoogleclassroom className="inline mr-1" />}
          onClick={() => {
            navigate("/class/add");
          }}
        />
      }
    >
      <AppControl control="table" columns={COLUMNS} data={classList} />
    </Card>
  );
};

export default AcademicClass;
