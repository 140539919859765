import "./App.css";
import { App, ConfigProvider } from "antd";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import Routes from "./routes/Routes";

const store = configureStore();
function MyApp() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#f97316",
          fontFamily: "Poppins",
        },
      }}
    >
      <App>
        <Provider store={store}>
          <Routes />
        </Provider>
      </App>
    </ConfigProvider>
  );
}

export default MyApp;
