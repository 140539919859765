import React, { useEffect, useState } from "react";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../../../components/";
import Card from "../../../components/controls/Card";
import { createYupSchema } from "../../../utilities/";
import { getRoles } from "../../../store/slice/roles";
import { getBranches } from "../../../store/slice/branches";
import { getUser, saveUser, updateUser } from "../../../store/slice/users";

function UserAdd() {
  const [title, setTitle] = useState(null);
  let { id } = useParams();
  const { message } = App.useApp();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pages = useSelector((state) => state.common.pages);
  const { isLoading, user } = useSelector((state) => state.users);
  const roleList = useSelector((state) => state.roles.list);
  const branchList = useSelector((state) => state.branches.list);
  const currentPage =
    pages &&
    (id
      ? pages.filter((item) => item.formName === "EditUser")
      : pages.filter((item) => item.formName === "User"));
  const currentPageControls = currentPage && currentPage[0].fields;

  useEffect(() => {
    dispatch(getBranches());
    dispatch(getRoles());
    if (id) {
      setTitle("EDIT USER");
      dispatch(getUser(id));
    } else {
      setTitle("CREATE A NEW USER");
    }
  }, []);

  let initialValues = {},
    dropdownValues = {},
    yepSchema,
    validationSchema;
  if (currentPageControls) {
    if (id && user) {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = user[control.fieldId])
      );
    } else {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = control.defaultValue)
      );
      initialValues["roles"] = [];
      initialValues["tenantNames"] = [];
    }
    yepSchema = currentPageControls.reduce(createYupSchema, {});
    validationSchema = Yup.object().shape(yepSchema);
  }
  if (branchList) {
    let schools = [];
    branchList.forEach(function (item, index) {
      schools.push({
        label: item.branchName,
        value: item.id,
      });
    });
    dropdownValues["tenantNames"] = schools;
  }
  if (roleList) {
    let roles = [];
    roleList.forEach(function (item, index) {
      roles.push({
        label: item.name,
        value: item.id,
      });
    });
    dropdownValues["roles"] = roles;
  }
  // console.log("user", user);
  console.log("initialValues", initialValues["roles"]);
  const onSubmit = async (values) => {
    console.log("Form data", values);
    if (id) {
      values.applicationName = "Vidyakalpa";
      values.id = user.id;

      const resultAction = await dispatch(updateUser(values));
      console.log("resultAction", resultAction);
      if (updateUser.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/users");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    } else {
      //values.userName = values.email;
      values.applicationName = "Vidyakalpa";

      const resultAction = await dispatch(saveUser(values));
      console.log("resultAction", resultAction);
      if (saveUser.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/users");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Card title={title}>
            <Form>
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                {currentPageControls &&
                  currentPageControls.map((control) => (
                    <AppControl
                      key={control.fieldId}
                      control={control.type}
                      label={control.label}
                      name={control.fieldId}
                      placeholder={control.placeholder}
                      options={
                        control.options && dropdownValues[control.fieldId]
                      }
                      //{...JSON.parse(control.Props)}
                    />
                  ))}
              </div>
              <div className="border-t-2 border-neutral-100 pt-3">
                <AppControl
                  control="button"
                  type="submit"
                  name="Save"
                  size="md"
                  color="orange"
                  disabled={!formik.isValid || isLoading}
                  loading={isLoading}
                />
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="md"
                  color="blue"
                  onClick={() => {
                    navigate("/users");
                  }}
                />
              </div>
            </Form>
          </Card>
        );
      }}
    </Formik>
  );
}

export default UserAdd;
