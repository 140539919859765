import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../client";

export const fetchFormElements = createAsyncThunk(
  "PageComponents",
  async (thunkAPI) => {
    const url = "Forms";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { pages } = getState().common;
      if (pages) return false;
    },
  }
);
export const fetchRegisterForm = createAsyncThunk(
  "RegisterComponent",
  async (thunkAPI) => {
    const url = "Forms/Register";
    return api.get(url).then(function (response) {
      return response.data;
    });
  }
);
export const fetchScreens = createAsyncThunk(
  "Screens",
  async (thunkAPI) => {
    const url = "Screen";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { screens } = getState().common;
      if (screens) return false;
    },
  }
);
export const getStates = createAsyncThunk(
  "States",
  async (thunkAPI) => {
    const url = "Common/States";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { states } = getState().common;
      if (states) return false;
    },
  }
);
export const getDistricts = createAsyncThunk("Districts", async (thunkAPI) => {
  const url = "Common/Districts";
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const getDistrictsByState = createAsyncThunk(
  "DistrictsByState",
  async (id, thunkAPI) => {
    const url = `Common/Districts/${id}`;
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (id, { getState, extra }) => {
      if (typeof id === "undefined" || id === null) return false;
    },
  }
);

const initialState = {
  pages: null,
  page: null,
  screens: null,
  districts: null,
  states: null,
  failedMessage: null,
  isLoading: false,
};

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormElements.fulfilled, (state, action) => {
        state.pages = action.payload.data;
      })
      .addCase(fetchFormElements.rejected, (state, action) => {
        state.failedMessage = action.payload;
      })
      .addCase(fetchScreens.fulfilled, (state, action) => {
        state.screens = action.payload.data;
      })
      .addCase(fetchScreens.rejected, (state, action) => {
        state.failedMessage = action.payload;
      })
      .addCase(fetchRegisterForm.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchRegisterForm.fulfilled, (state, action) => {
        state.page = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchRegisterForm.rejected, (state, action) => {
        state.failedMessage = action.payload;
        state.isLoading = false;
      })
      .addCase(getStates.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.states = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getStates.rejected, (state, action) => {
        state.failedMessage = action.payload;
        state.isLoading = false;
      })
      .addCase(getDistricts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.districts = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getDistricts.rejected, (state, action) => {
        state.failedMessage = action.payload;
        state.isLoading = false;
      })
      .addCase(getDistrictsByState.pending, (state, action) => {
        state.isLoading = true;
        state.districts = null;
      })
      .addCase(getDistrictsByState.fulfilled, (state, action) => {
        state.districts = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getDistrictsByState.rejected, (state, action) => {
        state.failedMessage = action.payload;
        state.isLoading = false;
      });
  },
});

export default slice.reducer;
