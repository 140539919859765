import React, { useEffect, useState } from "react";
import { App, Card, Typography } from "antd";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import AppControl from "../../../components";
import { createYupSchema } from "../../../utilities";
import { fetchScreens } from "../../../store/slice/common";
import { getRole, saveRole, updateRole } from "../../../store/slice/roles";

const RoleAdd = () => {
  const [title, setTitle] = useState(null);
  let { id } = useParams();
  const { message } = App.useApp();
  const { Title } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pages, screens } = useSelector((state) => state.common);
  const { role, isLoading } = useSelector((state) => state.roles);

  const currentPage = pages && pages.filter((item) => item.formName === "Role");
  const currentPageControls = currentPage && currentPage[0].fields;

  useEffect(() => {
    dispatch(fetchScreens());
    if (id) {
      setTitle("Edit Role");
      dispatch(getRole(id));
    } else {
      setTitle("CREATE A NEW ROLE");
    }
  }, []);

  let initialValues = {},
    yepSchema,
    validationSchema;
  if (currentPageControls) {
    if (id && role) {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = role[control.fieldId])
      );
    } else {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = control.defaultValue)
      );
    }
    yepSchema = currentPageControls.reduce(createYupSchema, {});
    validationSchema = Yup.object().shape(yepSchema);
  }

  console.log("initialValues", initialValues);
  const onSubmit = async (values) => {
    console.log("Form data", values);
    if (id) {
      values.id = role.id;
      const resultAction = await dispatch(updateRole(values));
      console.log("resultAction", resultAction);
      if (updateRole.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/roles");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    } else {
      const resultAction = await dispatch(saveRole(values));
      console.log("resultAction", resultAction);
      if (saveRole.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/roles");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Card title={<Title level={5}>{title}</Title>}>
              <Form>
                <div className="md:columns-1 mb-3">
                  {currentPageControls &&
                    title &&
                    screens &&
                    currentPageControls.map((control) => (
                      <AppControl
                        key={control.fieldId}
                        control={control.type}
                        label={control.label}
                        name={control.fieldId}
                        placeholder={control.placeholder}
                        options={screens}
                      />
                    ))}
                </div>
                <AppControl
                  control="button"
                  type="submit"
                  name="Save"
                  size="md"
                  color="orange"
                  disabled={!formik.isValid || isLoading}
                  loading={isLoading}
                />
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="md"
                  color="blue"
                  onClick={() => {
                    navigate("/roles");
                  }}
                />
              </Form>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
};

export default RoleAdd;
