import { Tree } from "antd";
import { useState } from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const ScreenTree = (props) => {
  const { label, name, placeholder, options, ...rest } = props;

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  //const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  let selectedData = [];

  return (
    <div className="mb-3">
      <div className="mb-1">
        <label htmlFor={name}>{label}</label>
      </div>
      <Field name={name}>
        {({ field, form }) => {
          const { value } = field;
          let claims = [];

          if (value && value.length > 0) {
            // console.log("value", value);
            value.forEach(function (item, index) {
              selectedData.push(item.value);
            });
            //console.log("selectedData", selectedData);
            //selectedData = value.split(",");
          }
          // else if (value) {
          //   selectedData = value;
          // }
          const onExpand = (expandedKeysValue) => {
            //console.log("onExpand", expandedKeysValue);
            // if not set autoExpandParent to false, if children expanded, parent can not collapse.
            // or, you can remove all expanded children keys.
            setExpandedKeys(expandedKeysValue);
            setAutoExpandParent(false);
          };
          const onCheck = (checkedKeysValue) => {
            console.log("onCheck", checkedKeysValue);
            setCheckedKeys(checkedKeysValue);
            checkedKeysValue.forEach(function (item, index) {
              var claim = {
                type: "permission",
                value: item,
                issuer: "IdentityService",
              };
              claims.push(claim);
            });
            form.setFieldValue(name, claims);
          };
          // const onSelect = (selectedKeysValue, info) => {
          //   console.log("onSelect", info);
          //   setSelectedKeys(selectedKeysValue);
          // };

          return (
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={selectedData}
              // onSelect={onSelect}
              // selectedKeys={selectedKeys}
              treeData={options}
            />
          );
          <ErrorMessage component={TextError} name={name} />;
        }}
      </Field>
    </div>
  );
};
export default ScreenTree;
