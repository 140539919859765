import React from "react";
import moment from "moment";
import { FieldArray, Field, ErrorMessage } from "formik";
import { Input, Select, DatePicker } from "antd";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";
import TextError from "./TextError";

function Owner(props) {
  const { label, name, placeholder, ...rest } = props;

  return (
    <FieldArray name="owners">
      {(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values } = form;
        const { owners } = values;
        // console.log('fieldArrayProps', fieldArrayProps)
        // console.log('Form errors', form.errors)
        return (
          <>
            {owners &&
              owners.map((owner, index) => (
                <div key={index}>
                  <div className="mb-2 mt-4 text-center font-bold">
                    <label htmlFor={`lblOwner[${index}]`}>
                      {`Owner - ${index + 1}`}
                    </label>
                  </div>
                  <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                    <Field name={`owners[${index}].name`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblOwnerName[${index}]`}>
                              Owner Name
                            </label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].name`}
                              name={`owners[${index}].name`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].qualification`}>
                      {({ field, form }) => {
                        const handleChange = (value) => {
                          console.log(`selected ${value}`);
                          form.setFieldValue(
                            `owners[${index}].qualification`,
                            value
                          );
                        };
                        return (
                          <div>
                            <div className="mb-1">
                              <label htmlFor={`lblQualification[${index}]`}>
                                Qualification
                              </label>
                            </div>
                            <div className="mb-1">
                              <Select
                                placeholder="Choose a Qualification"
                                style={{
                                  width: "100%",
                                }}
                                status={
                                  form.errors[name] && form.touched[name]
                                    ? "error"
                                    : ""
                                }
                                options={[
                                  {
                                    value: "Below Matriculation",
                                    label: "Below Matriculation",
                                  },
                                  {
                                    value: "Matriculation",
                                    label: "Matriculation",
                                  },
                                  {
                                    value: "Graduate",
                                    label: "Graduate",
                                  },
                                  {
                                    value: "Post Graduate",
                                    label: "Post Graduate",
                                  },
                                  {
                                    value: "Professional",
                                    label: "Professional",
                                  },
                                  {
                                    value: "Higher Secondary",
                                    label: "Higher Secondary",
                                  },
                                ]}
                                onChange={handleChange}
                              />
                            </div>
                            <ErrorMessage component={TextError} name={name} />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name={`owners[${index}].designation`}>
                      {({ field, form }) => {
                        const handleChange = (value) => {
                          console.log(`selected ${value}`);
                          form.setFieldValue(
                            `owners[${index}].designation`,
                            value
                          );
                        };
                        return (
                          <div>
                            <div className="mb-1">
                              <label htmlFor={`lblDesignation[${index}]`}>
                                Designation
                              </label>
                            </div>
                            <div className="mb-1">
                              <Select
                                placeholder="Choose a Designation"
                                style={{
                                  width: "100%",
                                }}
                                status={
                                  form.errors[name] && form.touched[name]
                                    ? "error"
                                    : ""
                                }
                                options={[
                                  {
                                    value: "TRUSTEE",
                                    label: "TRUSTEE",
                                  },
                                  {
                                    value: "MEMBER OF SOCIETY",
                                    label: "MEMBER OF SOCIETY",
                                  },
                                  {
                                    value: "DIRECTOR",
                                    label: "DIRECTOR",
                                  },
                                ]}
                                onChange={handleChange}
                              />
                            </div>
                            <ErrorMessage component={TextError} name={name} />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name={`owners[${index}].dob`}>
                      {({ form, field }) => {
                        //const { value } = field;
                        const onChange = (date, dateString) => {
                          console.log(date, moment(dateString).format());
                          form.setFieldValue(
                            `owners[${index}].dob`,
                            moment(dateString).format()
                          );
                        };
                        return (
                          <div>
                            <div className="mb-1">
                              <label htmlFor={`lblDob[${index}]`}>DOB</label>
                            </div>
                            <div className="mb-1">
                              <DatePicker
                                id={`owners[${index}].dob`}
                                format={"DD/MM/YYYY"}
                                onChange={onChange}
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                    <Field name={`owners[${index}].doj`}>
                      {({ form, field }) => {
                        //const { value } = field;
                        const onChange = (date, dateString) => {
                          console.log(date, moment(dateString).format());
                          form.setFieldValue(
                            `owners[${index}].doj`,
                            moment(dateString).format()
                          );
                        };
                        return (
                          <div>
                            <div className="mb-1">
                              <label htmlFor={`lblDoj[${index}]`}>DOJ</label>
                            </div>
                            <div className="mb-1">
                              <DatePicker
                                id={`owners[${index}].doj`}
                                format={"DD/MM/YYYY"}
                                onChange={onChange}
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                    <Field name={`owners[${index}].aadharNo`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblAadharNo[${index}]`}>
                              Aadhar No
                            </label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].aadharNo`}
                              name={`owners[${index}].aadharNo`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].voterNo`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblVoterNo[${index}]`}>
                              Voter No
                            </label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].voterNo`}
                              name={`owners[${index}].voterNo`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].panNo`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblPanNo[${index}]`}>Pan No</label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].panNo`}
                              name={`owners[${index}].panNo`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].primaryContactNo`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblPrimaryContactNo[${index}]`}>
                              Primary Contact No
                            </label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].primaryContactNo`}
                              name={`owners[${index}].primaryContactNo`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].secondaryContactNo`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblSecondaryContactNo[${index}]`}>
                              Secondary Contact No
                            </label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].secondaryContactNo`}
                              name={`owners[${index}].secondaryContactNo`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    <Field name={`owners[${index}].email`}>
                      {({ field, form }) => (
                        <div>
                          <div className="mb-1">
                            <label htmlFor={`lblEmail[${index}]`}>Email</label>
                          </div>
                          <div className="mb-1">
                            <Input
                              placeholder={placeholder}
                              id={`owners[${index}].email`}
                              name={`owners[${index}].email`}
                              status={
                                form.errors[name] && form.touched[name]
                                  ? "error"
                                  : ""
                              }
                              {...field}
                            />
                          </div>
                          <ErrorMessage component={TextError} name={name} />
                        </div>
                      )}
                    </Field>
                    {index > 0 && (
                      <div className="flex flex-row-reverse items-center">
                        <button
                          type="button"
                          className="flex items-center rounded px-6 pb-2 pt-2.5 h-8 text-xs font-medium leading-normal text-white bg-rose-500 hover:bg-rose-600"
                          onClick={() => remove(index)}
                        >
                          <FaUserMinus className="mr-2" /> Remove Owner
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            <button
              type="button"
              className="flex items-center rounded px-6 pb-2 mb-3 pt-2.5 text-xs font-medium leading-normal text-white bg-green-500 hover:bg-green-600"
              onClick={() => push("")}
            >
              <FaUserPlus className="mr-2" />
              Add Owner
            </button>
          </>
        );
      }}
    </FieldArray>
  );
}

export default Owner;
