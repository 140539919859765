import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../components/";
import { loginUser } from "../store/slice/auth";
import AppNotificationContainer from "../components/controls/AppNotificationContainer";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isLoading, message, error } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
        <img
          className="w-full h-48 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
          src="assets/images/school.jpg"
          alt=""
        />
        <div className="p-6 flex flex-col w-96 justify-start">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <h5 className="text-center text-gray-900 text-xl font-medium mb-2">
                    Sign In
                  </h5>
                  <AppControl
                    control="text"
                    //type="email"
                    label="Email"
                    name="email"
                    visible="true"
                  />
                  <AppControl
                    control="password"
                    //type="password"
                    label="Password"
                    name="password"
                    visible="true"
                  />
                  <div className="flex justify-between items-center mb-2">
                    <div className="form-group form-check">
                      <input
                        className="form-checkbox accent-orange-500 h-4 w-4 mt-1 float-left mr-2 cursor-pointer"
                        id="exampleCheck2"
                        type="checkbox"
                      />

                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="exampleCheck2"
                      >
                        Remember me
                      </label>
                    </div>
                    <Link
                      to="/reset-password"
                      className="text-orange-500 hover:text-orange-600 focus:text-orange-600 transition duration-200 ease-in-out"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <AppControl
                    control="button"
                    type="submit"
                    name="Sign In"
                    size="full"
                    color="orange"
                    disabled={!formik.isValid || isLoading}
                    loading={isLoading}
                  />
                  <p className="text-gray-800 mt-6 text-center">
                    Not a member?{" "}
                    <Link
                      to="/register"
                      className="text-orange-500 hover:text-orange-600 focus:text-orange-600 transition duration-200 ease-in-out"
                    >
                      Register
                    </Link>
                  </p>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AppNotificationContainer
        loading={isLoading}
        msg={message}
        error={error}
      />
    </div>
  );
}

export default Login;
