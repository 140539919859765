import React from "react";
import { Button } from "antd";
function Home() {
  return (
    <>
      <div className="p-6 shadow-lg rounded-lg text-gray-700">
        <h2 className="font-semibold text-3xl mb-5">Welcome Back to Home!</h2>
        <p>
          This is a simple hero unit, a simple jumbotron-style component for
          calling extra attention to featured content or information.
        </p>
        <hr className="my-6 border-gray-300" />
        <p>
          It uses utility classes for typography and spacing to space content
          out within the larger container.
        </p>

        <Button type="primary">Learn more</Button>
      </div>
    </>
  );
}

export default Home;
