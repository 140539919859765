import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import api from "../client";

export const getRoles = createAsyncThunk(
  "Role/list",
  async (thunkAPI) => {
    const url = "role";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { lastFetch } = getState().roles;
      const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
      if (diffInMinutes < process.env.REACT_APP_CACHE_TIME) return false;
    },
  }
);
export const getRole = createAsyncThunk("Role", async (id, thunkAPI) => {
  const url = `role/${id}`;
  return api.get(url).then(function (response) {
    return response.data;
  });
});
export const saveRole = createAsyncThunk(
  "Role/save",
  async (role, thunkAPI) => {
    const url = "role";
    return api.post(url, role).then(function (response) {
      return response.data;
    });
  }
);
export const updateRole = createAsyncThunk(
  "Role/update",
  async (role, thunkAPI) => {
    const url = `role/${role.id}`;
    return api.put(url, role).then(function (response) {
      return response.data;
    });
  }
);

const initialState = {
  list: [],
  role: null,
  isLoading: false,
  isSucceed: false,
  message: null,
  lastFetch: null,
};

const slice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state, action) => {
        state.isLoading = true;
        state.isSucceed = false;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isSucceed = action.payload.succeeded;
        state.list = action.payload.data;
        state.isLoading = false;
        state.lastFetch = Date.now();
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isSucceed = false;
        state.message = action.payload;
      })
      .addCase(getRole.pending, (state, action) => {
        state.isLoading = true;
        state.isSucceed = false;
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.isSucceed = action.payload.succeeded;
        state.role = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isSucceed = false;
        state.message = action.payload.message;
      })
      .addCase(saveRole.pending, (state, action) => {
        state.isLoading = true;
        state.isSucceed = false;
      })
      .addCase(saveRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucceed = action.payload.succeeded;
        state.message = action.payload.message;
      })
      .addCase(saveRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isSucceed = false;
        state.message = action.payload.message;
      })
      .addCase(updateRole.pending, (state, action) => {
        state.isLoading = true;
        state.isSucceed = false;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucceed = action.payload.succeeded;
        state.message = action.payload.message;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isSucceed = false;
        state.message = action.payload.message;
      });
  },
});

export default slice.reducer;
