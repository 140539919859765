import React, { useEffect, useState } from "react";
import { App, Button, Steps } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useFormikContext, Formik, Form } from "formik";
import AppControl from "../../components/";
import { createYupSchema } from "../../utilities/";
import Card from "../../components/controls/Card";
import {
  getStates,
  getDistricts,
  getDistrictsByState,
} from "../../store/slice/common";
import StudentInfo from "./StudentInfo";
import GuardianInfo from "./GuardianInfo";
import ResidentialInfo from "./ResidentialInfo";
import PreviousSchool from "./PreviousSchool";
import OtherInfo from "./OtherInfo";
import { getBranches } from "../../store/slice/branches";
import { getAcademicYearsByBranch } from "../../store/slice/academicyears";
import { getClassesByBranch } from "../../store/slice/academicclasses";
import { getSectionsByClass } from "../../store/slice/sections";
import { saveStudent } from "../../store/slice/students";

const steps = [
  {
    title: "Student Info",
    component: <StudentInfo />,
  },
  {
    title: "Guardian's Info",
    component: <GuardianInfo />,
  },
  {
    title: "Residential Info",
    component: <ResidentialInfo />,
  },
  {
    title: "Previous School Info",
    component: <PreviousSchool />,
  },
  {
    title: "Other Info",
    component: <OtherInfo />,
  },
];

const DropdownChanged = () => {
  const { values, status } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values["branchId"]) {
      dispatch(getAcademicYearsByBranch(values["branchId"]));
      dispatch(getClassesByBranch(values["branchId"]));
      values["sessionId"] = null;
      values["classId"] = null;
    }
    console.log("branchId-", values["branchId"]);
  }, [values["branchId"]]);
  useEffect(() => {
    if (values["classId"]) {
      dispatch(getSectionsByClass(values["classId"]));
      values["sectionId"] = null;
    }
    console.log("classId-", values["classId"]);
  }, [values["classId"]]);
  useEffect(() => {
    if (values["caste"] && values["caste"] === "GENERAL") {
      console.log("caste-", values["caste"]);
      // values["sectionId"] = null;
    }
  }, [values["caste"]]);
  useEffect(() => {
    if (values["guardians"][0].isGuardian)
      values["guardians"][1].isGuardian = false;
    else values["guardians"][1].isGuardian = true;
  }, [values["guardians"][0].isGuardian]);
  useEffect(() => {
    if (values["guardians"][1].isGuardian)
      values["guardians"][0].isGuardian = false;
    else values["guardians"][0].isGuardian = true;
  }, [values["guardians"][1].isGuardian]);
  useEffect(() => {
    dispatch(getDistrictsByState(values["addresses"][0].stateCode));
    values["addresses"][0].districtCode = null;
  }, [values["addresses"][0].stateCode]);
  useEffect(() => {
    dispatch(getDistrictsByState(values["addresses"][1].stateCode));
    if (!values["addresses"][1].isSame) {
      values["addresses"][1].districtCode = null;
    }
  }, [values["addresses"][1].stateCode]);
  useEffect(() => {
    if (values["addresses"][1].isSame) {
      values["addresses"][1].stateCode = values["addresses"][0].stateCode;
      values["addresses"][1].districtCode = values["addresses"][0].districtCode;
      values["addresses"][1].villageTown = values["addresses"][0].villageTown;
      values["addresses"][1].postOffice = values["addresses"][0].postOffice;
      values["addresses"][1].municipality = values["addresses"][0].municipality;
      values["addresses"][1].block = values["addresses"][0].block;
      values["addresses"][1].policeStation =
        values["addresses"][0].policeStation;
      values["addresses"][1].pinCode = values["addresses"][0].pinCode;
    } else {
      values["addresses"][1].stateCode = null;
      values["addresses"][1].districtCode = null;
      values["addresses"][1].villageTown = null;
      values["addresses"][1].postOffice = null;
      values["addresses"][1].municipality = null;
      values["addresses"][1].block = null;
      values["addresses"][1].policeStation = null;
      values["addresses"][1].pinCode = null;
    }
  }, [values["addresses"][1].isSame]);
  return null;
};

const StudentAddEdit = () => {
  const [title, setTitle] = useState(null);
  let { id } = useParams();
  const { message } = App.useApp();
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onChange = (current) => {
    setCurrent(current);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, user } = useSelector((state) => state.auth);
  const pages = useSelector((state) => state.common.pages);

  useEffect(() => {
    dispatch(getBranches());
    dispatch(getStates());
    dispatch(getDistricts());
    if (id) {
      setTitle("EDIT STUDENT");
    } else {
      setTitle("CREATE A NEW STUDENT");
    }
  }, []);

  const initialValues = {};
  let validationSchema;
  if (pages) {
    const studentInfo = pages.filter((item) => item.formName === "StudentInfo");
    const previousSchool = pages.filter(
      (item) => item.formName === "PreviousSchool"
    );
    const otherInfo = pages.filter((item) => item.formName === "OtherInfo");
    studentInfo[0].fields.map(
      (control) => (initialValues[control.fieldId] = control.defaultValue)
    );
    previousSchool[0].fields.map(
      (control) => (initialValues[control.fieldId] = control.defaultValue)
    );
    otherInfo[0].fields.map(
      (control) => (initialValues[control.fieldId] = control.defaultValue)
    );
    initialValues["pwd"] = false;

    const studentInfoSchema = studentInfo[0].fields.reduce(createYupSchema, {});
    const guardianInfoSchema = {
      guardians: Yup.array().of(
        Yup.object().shape({
          fullName: Yup.string().required("Full Name is required"),
          mobile: Yup.string()
            .required("Mobile number is required")
            .matches(/^[6-9]\d{9}$/, {
              message: "Please enter valid mobile number.",
              excludeEmptyString: false,
            }),
          email: Yup.string().email(),
          aadhar: Yup.string().matches(
            /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
            "Invalid aadhar no."
          ),
          voter: Yup.string().matches(
            /^([a-zA-Z]){3}([0-9]){7}?$/,
            "Invalid voter no."
          ),
        })
      ),
    };
    const adressInfoSchema = {
      addresses: Yup.array().of(
        Yup.object().shape({
          stateCode: Yup.number().required("State is required"),
          districtCode: Yup.number().required("District is required"),
          villageTown: Yup.string().required("Village/Town is required"),
          pinCode: Yup.string()
            .required("Pin code is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be exactly 6 digits")
            .max(6, "Must be exactly 6 digits"),
        })
      ),
    };
    // console.log(studentInfoSchema);
    // console.log(guardianInfoSchema);
    validationSchema = [
      Yup.object().shape(studentInfoSchema),
      Yup.object().shape(guardianInfoSchema),
      Yup.object().shape(adressInfoSchema),
    ];
    console.log(validationSchema);
    initialValues["addresses"] = [{ type: "Present" }, { type: "Permanent" }];
    initialValues["guardians"] = [
      {
        relation: "Father",
        isGuardian: true,
        remarks: null,
        annualIncome: null,
        incomeCertificate: null,
        alternateMobile: null,
      },
      {
        relation: "Mother",
        isGuardian: false,
        remarks: null,
        annualIncome: null,
        incomeCertificate: null,
        alternateMobile: null,
      },
    ];
  }
  const currentValidationSchema = validationSchema[current];
  console.log(currentValidationSchema);
  if (user) initialValues["branchId"] = user.tenantNames[0];
  console.log("initialValues", initialValues);

  const onSubmit = async (values, { resetForm }) => {
    values.photo = null;
    values.signature = null;
    values.studentCode = "001";
    values.admissionClass = "1";
    values.previousSchoolAddress = null;
    console.log("Form data", values);
    const resultAction = await dispatch(saveStudent(values));
    console.log("resultAction", resultAction);
    if (saveStudent.fulfilled.match(resultAction)) {
      message.success(resultAction.payload.message);
      resetForm();
      //navigate("/section");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        //formikHelpers.setErrors(resultAction.payload.field_errors)
      } else {
        message.error(`Save failed: ${resultAction.error.message}`);
      }
    }
  };

  return (
    <Card title={title}>
      <Formik
        initialValues={initialValues}
        //enableReinitialize
        validationSchema={currentValidationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, setTouched, validateForm }) => {
          return (
            <Form>
              <Steps
                current={current}
                labelPlacement="vertical"
                // onChange={async () => {
                //   const validationErrors = await validateForm();
                //   console.log(validationErrors);
                //   if (Object.keys(validationErrors).length > 0) {
                //     const obj = Object.keys(validationErrors).reduce(
                //       (o, key) => ({ ...o, [key]: true }),
                //       {}
                //     );
                //     console.log(obj);
                //     setTouched(obj);
                //     return;
                //   } else {
                //     onChange();
                //   }
                // }}
                onChange={onChange}
                items={steps}
                progressDot
              ></Steps>

              {JSON.stringify(errors, null, 2)}
              <div> {steps[current].component}</div>

              <div className="border-t-2 border-neutral-100 pt-3">
                {current > 0 && (
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    className="mr-3"
                    onClick={async () => {
                      const validationErrors = await validateForm();
                      console.log(validationErrors);
                      if (Object.keys(validationErrors).length > 0) {
                        if (validationErrors.hasOwnProperty("guardians")) {
                          console.log(validationErrors.guardians);
                          const fields = {
                            guardians: validationErrors.guardians.map(
                              (key) =>
                                key &&
                                Object.keys(key).reduce(
                                  (o, key) => ({ ...o, [key]: true }),
                                  {}
                                )
                            ),
                          };
                          console.log(fields);
                          setTouched(fields);
                        } else if (
                          validationErrors.hasOwnProperty("addresses")
                        ) {
                          console.log(validationErrors.addresses);
                          const fields = {
                            addresses: validationErrors.addresses.map(
                              (key) =>
                                key &&
                                Object.keys(key).reduce(
                                  (o, key) => ({ ...o, [key]: true }),
                                  {}
                                )
                            ),
                          };
                          console.log(fields);
                          setTouched(fields);
                        } else {
                          const obj = Object.keys(validationErrors).reduce(
                            (o, key) => ({ ...o, [key]: true }),
                            {}
                          );
                          console.log(obj);
                          setTouched(obj);
                        }
                        return;
                      } else {
                        next();
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <AppControl
                    control="button"
                    type="submit"
                    name="Save"
                    size="sm"
                    color="orange"
                    // disabled={!formik.isValid || isLoading}
                    // loading={isLoading}
                  />
                )}
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="sm"
                  color="blue"
                  onClick={() => {
                    navigate("/students");
                  }}
                />
                <DropdownChanged />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default StudentAddEdit;
