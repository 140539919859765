import React, { useEffect, useState } from "react";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../../components/";
import Card from "../../components/controls/Card";
import { createYupSchema } from "../../utilities/";
import { getBranches } from "../../store/slice/branches";
import {
  getAcademicYear,
  saveAcademicYear,
  updateAcademicYear,
} from "../../store/slice/academicyears";

const AcademicYearAddEdit = () => {
  const [title, setTitle] = useState(null);
  let { id } = useParams();
  const { message } = App.useApp();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pages = useSelector((state) => state.common.pages);
  const { user } = useSelector((state) => state.auth);
  const branchListBox = useSelector((state) => state.branches.listBox);
  const { academicYear } = useSelector((state) => state.academicyears);
  const currentPage =
    pages && pages.filter((item) => item.formName === "Session");
  const currentPageControls = currentPage && currentPage[0].fields;

  useEffect(() => {
    dispatch(getBranches());
    if (id) {
      setTitle("EDIT SESSION");
      dispatch(getAcademicYear(id));
    } else {
      setTitle("CREATE A NEW SESSION");
    }
  }, []);

  let initialValues = {},
    options = {},
    yepSchema,
    validationSchema;
  if (currentPageControls) {
    if (id && academicYear) {
      currentPageControls.map(
        (control) =>
          (initialValues[control.fieldId] = academicYear[control.fieldId])
      );
    } else {
      currentPageControls.map(
        (control) => (initialValues[control.fieldId] = control.defaultValue)
      );
      if (user) initialValues["branchId"] = user.tenantNames[0];
    }

    yepSchema = currentPageControls.reduce(createYupSchema, {});
    validationSchema = Yup.object().shape(yepSchema);
  }
  if (branchListBox) options["branchId"] = branchListBox;
  console.log("initialValues", initialValues);
  const onSubmit = async (values) => {
    console.log("Form data", values);
    if (id) {
      values.id = academicYear.id;
      const resultAction = await dispatch(updateAcademicYear(values));
      console.log("resultAction", resultAction);
      if (updateAcademicYear.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/session");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    } else {
      const resultAction = await dispatch(saveAcademicYear(values));
      console.log("resultAction", resultAction);
      if (saveAcademicYear.fulfilled.match(resultAction)) {
        message.success(resultAction.payload.message);
        navigate("/session");
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          message.error(`Save failed: ${resultAction.error.message}`);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Card title={title}>
            <Form>
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-2">
                {currentPageControls &&
                  currentPageControls.map((control) => (
                    <AppControl
                      key={control.fieldId}
                      control={control.type}
                      label={control.label}
                      name={control.fieldId}
                      placeholder={control.placeholder}
                      options={control.options && options[control.fieldId]}
                      //{...JSON.parse(control.Props)}
                    />
                  ))}
              </div>
              <div className="border-t-2 border-neutral-100 pt-3">
                <AppControl
                  control="button"
                  type="submit"
                  name="Save"
                  size="md"
                  color="orange"
                  //   disabled={!formik.isValid || isLoading}
                  //   loading={isLoading}
                />
                <AppControl
                  control="button"
                  type="reset"
                  name="Cancel"
                  size="md"
                  color="blue"
                  onClick={() => {
                    navigate("/session");
                  }}
                />
              </div>
            </Form>
          </Card>
        );
      }}
    </Formik>
  );
};

export default AcademicYearAddEdit;
