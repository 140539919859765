import React, { useEffect } from "react";
import { Button, Dropdown, Modal, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HiCalendar, HiOutlineChevronDown } from "react-icons/hi";
import moment from "moment";
import AppControl from "../../components";
import Card from "../../components/controls/Card";
import { getAcademicYears } from "../../store/slice/academicyears";

const AcademicYear = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAcademicYears());
  }, []);
  const sessionList = useSelector((state) => state.academicyears.list);

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
      isVisible: false,
      disableFilters: true,
    },
    {
      Header: "Branch",
      accessor: "branches[0].branchName",
    },
    {
      Header: "Session Name",
      accessor: "sessionName",
      disableFilters: true,
    },
    {
      Header: "Start Date",
      disableFilters: true,
      accessor: (d) => {
        return moment(d.startDate).local().format("DD-MM-YYYY");
      },
    },
    {
      Header: "End Date",
      disableFilters: true,
      accessor: (row) => {
        return moment(row.endDate).local().format("DD-MM-YYYY");
      },
    },
    {
      Header: "Actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <Link to={`edit/${row.values.id}`}>Edit</Link>,
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions <HiOutlineChevronDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return (
    <Card
      title="SESSION LIST"
      extra={
        <AppControl
          control="button"
          type="reset"
          name="Add New Session"
          size="full"
          color="orange"
          icon={<HiCalendar className="inline mr-1" />}
          onClick={() => {
            navigate("/session/add");
          }}
        />
      }
    >
      <AppControl control="table" columns={COLUMNS} data={sessionList} />
    </Card>
  );
};

export default AcademicYear;
